import React from "react";
import "./Latest.css";
import TwitterFeed from "../../components/TwitterFeed/TwitterFeed";
import InstagramFeed from "../../components/InstagramFeed/InstagramFeed";

export default function Latest() {
    return (
        <main>
            <div id="latestContainer">
                <InstagramFeed />
                <TwitterFeed />
            </div>
        </main>
    )
}