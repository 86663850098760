import React from "react";
import "./Footer.css";
import "materialize-css";
import { Icon } from "react-materialize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faPiggyBank } from "@fortawesome/free-solid-svg-icons";

export default function Footer() {
    return (
        <footer>
            <div className="container">
                <div id="socialNav">
                    <a href="mailto:muzachpoyatt@gmail.com" target="_blank" rel="noreferrer"><Icon id="mailIcon">email</Icon></a>
                    <a href="https://www.instagram.com/zachpoyatt/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram} size="3x" /></a>
                    <a href="https://venmo.com/?txn=pay&audience=friends&recipients=Zach-Poyatt" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faPiggyBank} size="3x" /></a>
                    <a href="https://www.youtube.com/channel/UC3OIKvu6Zw6EWrKiEsNxglQ" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faYoutube} size="3x" /></a>
                </div>
            </div>
        </footer>
    )
}