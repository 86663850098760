import React, { useEffect, useState } from "react";
import "./InstagramFeed.css";

export default function InstagramPost() {
    const loadCurator = (callback) => {
        const loadedScript = document.getElementById('latestFeed');
        if (!loadedScript) {
            const script = document.createElement('script');
            script.src = "https://cdn.curator.io/published/b4526e99-1113-41fe-a77a-75954e91381a.js";
            script.id = 'latestFeed';
            document.body.appendChild(script);
            script.onload = () => {
                if (callback) callback();
            };
        }
        if (loadedScript && callback) callback();
    }

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        loadCurator(() => {
            setLoaded(true);
        });
    });

    return (
        <section>
            {loaded ? <div id="curator-feed-latestpage-layout"><a href="https://curator.io" target="_blank" rel="noreferrer" class="crt-logo crt-tag">Powered by Curator.io</a></div> : ""}
        </section>
    )
}