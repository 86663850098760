import React from "react";
import "./Hero.css";

export default function Hero() {
    return (
        <div id="hero" className="row">
            <h1 className="blue-grey-text text-lighten-5 col s6 offset-s6">Zach Poyatt</h1>
            <h2 className="blue-grey-text text-lighten-5 col s6 offset-s6">Music Teacher & Performer</h2>
        </div>
    )
}