import React from "react";
import Hero from "../../components/Hero/Hero";
import InstagramPost from "../../components/InstagramPost/InstagramPost";

export default function Home() {
    return (
        <main>
            <Hero />
            <InstagramPost />
        </main>
    )
}