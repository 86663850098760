import React from "react";
import "./TwitterFeed.css";
import { Timeline } from "react-twitter-widgets";

export default function InstagramPost() {
    return (
        <div id="twitterFeed">
            <Timeline dataSource={{ sourceType: "collection", id: "1343324559353597953" }} options={{ chrome: "nofooter" }} />
        </div>
    )
}