import React, {useEffect, useState } from "react";
import "./InstagramPost.css";

export default function InstagramPost() {
    const loadCurator = (callback) => {
        const loadedScript = document.getElementById('homeFeed');
        if(!loadedScript) {
            const script = document.createElement('script');
            script.src = 'https://cdn.curator.io/published/916eb59f-d9e2-495e-b20b-562b7aae6bd8.js';
            script.id = 'homeFeed';
            document.body.appendChild(script);
            script.onload = () => {
                if (callback) callback();
            };
        }
        if (loadedScript && callback) callback();
    }

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        loadCurator(() => {
            setLoaded(true);
        });
    });

    return (
        <section className="blue lighten-5">
            <h4>What's New</h4>
            {loaded ? <div id="curator-feed-default-feed-layout" className="defaultPosts"><a href="https://curator.io" target="_blank" rel="noreferrer" className="crt-logo crt-tag">Powered by Curator.io</a></div> : ""}
        </section>
    )
}